"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'ファンタジスタ',
    organizationId: '0wTsgjvxkWfpst4LwDjr',
    version: '1.0.0',
    ionicAppId: '',
    applicationKey: 'ishizukaakari',
    applicationType: 'standalone',
    artistId: '4M3gYsSGBKXiikvA3SQCyE6Uv7v2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.ishizukaakari',
        appId: '',
    },
    android: {
        bundleId: 'com.utoniq.ishizukaakari',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: true,
            membership: true,
            thread: true,
            prepaidPoint: {
                chargeable: true,
            },
            gacha: false,
            nft: false,
            liveStream: true,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'collections', 'threads', 'trade', 'myProfile'],
    },
    deeplinkUrl: 'https://ishizukaakari.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3564140',
    storagePath: 'ishizukaakari',
    dynamicLinkPrefix: 'ishizukaakari',
    deeplinkProd: 'ishizukaakari.utoniq.com',
};
exports.default = appConfig;
